import { useState, useEffect, useRef } from 'react';
import CourseCard from './CourseCard'
export default function TrendingCourses() {

    const apiUrl = process.env.REACT_APP_API_URL;

    const hasFetched = useRef(false);
    const [trendingCourses, setTrendingCourses] = useState([]);
    useEffect(() => {
        if (hasFetched.current) return; // Prevent second call in dev mode
        fetch(`${apiUrl}Home/getCoursesList/top_10`).then(
            res => res.json()
        ).then(data => setTrendingCourses(data?.data))
        hasFetched.current = true;
    }, [apiUrl])




    return (

        <section className="featured-courses-sec">
            <div className="patter-one-main">
                <img className="patter-one" src="assets/img/bg/patter-one.png" alt="Img" />
            </div>
            <div className="container">
                <div className="header-two-title text-center" data-aos="fade-up">
                    <p className="tagline">What’s New</p>
                    <h2>Trending Courses</h2>
                    <div className="header-two-text m-auto text-center d-block">
                        <p className="mb-0">Stay ahead of the curve with our Trending Courses, carefully curated to address the most sought-after skills and topics in today's rapidly evolving landscape. From cutting-edge technologies to emerging industry trends, our courses are designed to equip you with the knowledge and expertise needed to thrive in your field.</p>
                    </div>
                </div>
                <div className="featured-courses-two">
                    <div className="row justify-content-center">


                        {
                            trendingCourses.map((course, index) => <CourseCard key={index} course={course} />)
                        }



                    </div>
                </div>

                <div className="col-lg-12" data-aos="fade-up">
                    <div className="more-details text-center">
                        <a href="course-details.html" className="discover-btn">View all Courses <i
                            className="fas fa-arrow-right ms-2"></i></a>
                    </div>
                </div>

            </div>
        </section>


    );
}