import { useEffect, useState, useRef } from 'react'
import UpcomingEventsImg from './assets/img/upcoming-events.svg'
import EventCard from './EventCard'

export default function UpcomingEvents(){
    const apiUrl = process.env.REACT_APP_API_URL;

    const hasFetched = useRef(false);
    const [allUpcomingEvents, setAllUpcomingEvents] = useState([]);
    useEffect(()=>{
        if (hasFetched.current) return; // Prevent second call in dev mode
        fetch(`${apiUrl}Events/getUpcomingEvents/2`).then(
            res => res.json()
        ).then(data => setAllUpcomingEvents(data?.data))
        hasFetched.current = true;
    }, [apiUrl])
    return (
        <section className="Webinar-section mt-5">
                 <div className="container">
                     <div className="row">
						<div className="col-md-4 mt-3">
							<h2>Upcoming events and challenges</h2>
							<img className="mt-4 up-coming-img" src={UpcomingEventsImg} alt="Upcoming Events" />
						</div>
						{
                            allUpcomingEvents.map((events, index) => <EventCard key={index} events={events} />)
                        }
					 </div>
				 </div>
				</section>
    )
}