import PencilIcon from './assets/img/pencil-icon.svg';
import CoursesIcon from './assets/img/cources-icon.svg';
import CertificateIcon from './assets/img/certificate-icon.svg';
import GrtuateIcon from './assets/img/gratuate-icon.svg';
const CompanyHighlights = () => {
    return (
        <section className="section student-course">
			<div className="container">
				<div className="course-widget">
					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div className="course-full-width">
								<div className="blur-border course-radius align-items-center aos" data-aos="fade-up">
									<div className="online-course d-flex align-items-center">
										<div className="course-img">
											<img src={PencilIcon} alt="Img" />
										</div>
										<div className="course-inner-content">
											<h4><span id="online_courses">0</span>+</h4>
											<p>Online Courses</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 d-flex">
							<div className="course-full-width">
								<div className="blur-border course-radius aos" data-aos="fade-up">
									<div className="online-course d-flex align-items-center">
										<div className="course-img">
											<img src={CoursesIcon} alt="Img" />
										</div>
										<div className="course-inner-content">
											<h4><span id="instructor_count"></span>+</h4>
											<p>Expert Tutors</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 d-flex">
							<div className="course-full-width">
								<div className="blur-border course-radius aos" data-aos="fade-up">
									<div className="online-course d-flex align-items-center">
										<div className="course-img">
											<img src={CertificateIcon} alt="Img" />
										</div>
										<div className="course-inner-content">
											<h4><span>6</span>K+</h4>
											<p>Ceritified Courses</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 d-flex">
							<div className="course-full-width">
								<div className="blur-border course-radius aos" data-aos="fade-up">
									<div className="online-course d-flex align-items-center">
										<div className="course-img">
											<img src={GrtuateIcon} alt="Img" />
										</div>
										<div className="course-inner-content" id="online_students">

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

export default CompanyHighlights;