import { useState, useReducer, useEffect, useRef } from 'react';


export default function CategoryCard() {

    const apiUrl = process.env.REACT_APP_API_URL;
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    const hasFetched = useRef(false);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        if (hasFetched.current) return; // Prevent second call in dev mode
        fetch(`${apiUrl}Home/getCategories`).then(
            res => res.json()
        ).then(data => setCategories(data?.data))
        hasFetched.current = true;
    }, [apiUrl])

    const getCategoryThumbnailUrl =(fileName) => {
        return fileName == null ? null : imageUrl + "uploads/thumbnails/category_thumbnails/" + fileName;
    }

    return (
        <section className="topcategory-sec">
            <div className="container">
                <div className="header-two-title text-center" data-aos="fade-up">
                    <p className="tagline">Favourite Course</p>
                    <h2>Top Category</h2>
                    <div className="header-two-text">
                        <p className="mb-0">Explore our Top Category, your gateway to diverse courses covering the most in-demand skills and subjects. From beginner basics to advanced mastery, dive into our top-rated courses and embark on a growth, discovery, and achievement journey.</p>
                    </div>
                </div>
                <div className="top-category-group">
                    <div className="row justify-content-center">

                        {categories.map((category, index) =>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex" data-aos="fade-down" key={category.id}>
                                <div className="categories-item flex-fill">
                                    <div className="categories-icon">
                                        <img src={getCategoryThumbnailUrl(category.thumbnail)} alt="Angular Development" />
                                    </div>
                                    <div className="categories-content">
                                        <h3>{category.name}</h3>
                                        <p>{category.course_count}{category.course_count == 1 ? ' Course' : ' Courses'}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="more-details text-center" data-aos="fade-down">
                        <a href="job-category.html" className="discover-btn">View all Category <i
                            className="fas fa-arrow-right ms-2"></i></a>
                    </div>
                </div>
            </div>
        </section>


    )
}