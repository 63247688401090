import ShareIcon from './assets/img/share.png';
export default function ShareKnowledge(){
    return (
        <section className="section share-knowledge">
			<div className="container">
				<div className="row">
					<div className="col-md-6">
						<div className="knowledge-img aos" data-aos="fade-up">
							<img src={ShareIcon} alt="Img" className="img-fluid" />
						</div>
					</div>
					<div className="col-md-6 d-flex align-items-center">
						<div className="join-mentor aos" data-aos="fade-up">
							<h2>Want to share your knowledge? Join us a Mentor</h2>
							<p>Become a Knowledge Leader: Join Us as a Trainer and Shape Futures
								Inspire Others: Become a Trainer and Share Your Expertise
								Join Our Training Team: Empower Others with Your Knowledge
								Training Opportunities Await: Make an Impact by Sharing Your Skills
							</p>
							<ul className="course-list">
								<li><i className="fa-solid fa-circle-check"></i>Best Courses</li>
								<li><i className="fa-solid fa-circle-check"></i>Top rated Instructors</li>
							</ul>
							<div className="all-btn all-category d-flex align-items-center">
								<a href="javascript:void(0);" className="btn btn-primary mbile-instructor">Read More</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}