import ObjImg from './assets/img/object.png';
import ThirdPartyRatings from './ThirdPartyRatings';
const HeroBlock = () =>{
    return (
        <section className="home-slide pt-5">
			<div className="container">
				<div className="row ">
					<div className="col-md-7">
						<div className="home-slide-face aos" data-aos="fade-up">
							<div className="home-slide-text ">
								<h5>The Leader in Online Learning</h5>
								<h1>Engaging & Accessible Online Courses For All</h1>
								<p>Own your future learning new skills online</p>
							</div>
							<div className="banner-content">
								<form className="form" action="course-list.php">
									<div className="form-inner">
										<div className="input-group">
											<i className="fa-solid fa-magnifying-glass search-icon"></i>
											<input type="text" className="form-control" name="course_title" placeholder="Search Course" />
											<span className="drop-detail">
												<select name="category" id="categoriesDropdown" className="form-select select">
													<option value="">Select Category</option>
												</select>
											</span>
											<button className="btn btn-primary sub-btn" type="submit"><i className="fas fa-arrow-right"></i></button>
										</div>
									</div>
								</form>
							</div>
							<div className="trust-user">
								<p>Trusted by over <span id="students_count"></span> Users <br/>worldwide since 2022</p>
								<div className="trust-rating d-flex align-items-center">
									<div className="rate-head" id="ratings_count">

									</div>
									<div className="rating d-flex align-items-center">
										<h2 className="d-inline-block average-rating" id="average_ratings"></h2>
										<i className="fas fa-star filled"></i>
										<i className="fas fa-star filled"></i>
										<i className="fas fa-star filled"></i>
										<i className="fas fa-star filled"></i>
										<i className="fas fa-star filled"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-5 d-flex align-items-center">
						<div className="girl-slide-img aos" data-aos="fade-up">
							<img src={ObjImg} alt="Img" />
						</div>
					</div>
					<ThirdPartyRatings />
				</div>
			</div>
		</section>
    );
}

export default HeroBlock;