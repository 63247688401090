export default function BlogCard(props){
    const convertUnixTimestampToDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleString('en-US', options); // Adjust the format as needed
    }

    const imageUrl = process.env.REACT_APP_IMAGE_URL;

    const getBlobThumbnailUrl = (fileName) => {
        return fileName == null ? null : imageUrl + "uploads/blog/thumbnail/" + fileName;
    }

    return (
        <div class="col-md-4 instructors-widget blog-widget">
            <div class="instructors-img">
                <a href="blog-list.html">
                    <img class="img-fluid" alt="Img" src={getBlobThumbnailUrl(props.blog.thumbnail)} />
                </a>
            </div>
            <div class="instructors-content text-center">
                <h5><a class="truncate-blog-title" href="blog-list.html">{props.blog.title}</a></h5>
                <div class="student-count d-flex justify-content-center">

                </div>
            </div>
        </div>
    )
}