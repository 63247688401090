import './Footer.css';
import logo from './assets/img/logo.svg';
import icon19 from './assets/img/icon/icon-19.svg';
import icon20 from './assets/img/icon/icon-20.svg';
import icon21 from './assets/img/icon/icon-21.svg';
import React, { useEffect, useRef, useState } from "react";
import parse from 'html-react-parser';

const Footer = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    const hasFetched = useRef(false);
    const [categories, setCategories] = useState([]);
    const [footerCourses, setFooterCourses] = useState([]);

    useEffect(() => {
        if (hasFetched.current) return;
        fetch(`${apiUrl}Home/getCategories`).then(
            res => res.json()
        ).then(data => setCategories(data?.data))

        fetch(`${apiUrl}Course/getFooterCourses`).then(
            res => res.json()
        ).then(data => setFooterCourses(data))
        hasFetched.current = true;
    }, [apiUrl])

    const composeCourseLinksHtml = (courses) => {
        let link_html = '';
      
        // Ensure `courses` is an array. If it's not, make it one.
        const courseArray = Array.isArray(courses) ? courses : [courses];
        
        courseArray.forEach((course) => {
            link_html += '<span>'+ course.title  +'</span>';
        });
    
        return link_html;
    };
    

    return (
        <footer className="footer">

            <div className="footer-top aos" data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">

                            <div className="footer-widget footer-about">
                                <div className="footer-logo">
                                    <img src={logo} alt="logo" />
                                </div>
                                <div className="icon right-icon">
                                    <ul className="nav">
                                        <li className="nav-item">
                                            <a target="_blank" href="https://www.facebook.com/techleadsitinstitute"><i className="fa-brands fa-facebook-f"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a target="_blank" href="https://twitter.com/techleads1"><i className="fa-brands fa-twitter"></i></a>
                                        </li>

                                        <li className="nav-item">
                                            <a target="_blank" href="https://www.linkedin.com/company/techleadsit1"><i className="fa-brands fa-linkedin"></i></a>
                                        </li>
                                        <a href="https://www.instagram.com/techleadsit/" target="_blank">
                                            <i className="fab fa-brands fa-square-instagram"></i>
                                        </a>
                                        <a href="https://www.youtube.com/@TechLeadsIT" target="_blank">
                                            <i className="fab fa-youtube"></i>
                                        </a>
                                    </ul>
                                </div>

                            </div>

                        </div>

                        <div className="col-6 col-lg-2 col-md-6">

                            <div className="footer-widget footer-menu">
                                <h2 className="footer-title">Top categories</h2>
                                <ul id="categories_links">
                                    {
                                        categories.map((category, index) => <li key={index}><a href="course-list.php?course_title=&category=${category.id}">{category.name}</a></li>)
                                    }
                                </ul>
                            </div>

                        </div>

                        <div className="col-6 col-lg-2 col-md-6">

                            <div className="footer-widget footer-menu">
                                <h2 className="footer-title">Useful Links</h2>
                                <ul>
                                    <li><a href="student-profile.html">Become an instructor</a></li>
                                    <li><a href="login.html">Refer a friend</a></li>
                                    <li><a href="course-list.php">Courses</a></li>
                                    <li><a href="blog-grid.php">Blogs</a></li>
                                    <li><a href="interview-questions-grid.php">Interview Question</a></li>
                                    <li><a href="job-grid.php">Jobs</a></li>
                                </ul>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-6">

                            <div className="footer-widget footer-contact">
                                <h2 className="footer-title">News letter</h2>
                                <div className="news-letter">
                                    <form>
                                        <input type="text" className="form-control" placeholder="Enter your email address" name="email" />
                                    </form>
                                </div>
                                <div className="footer-contact-info">
                                    <div className="footer-address">
                                        <img src={icon20} alt="Address Icon" className="img-fluid" />
                                        <p> Plot no 85, 1st Floor, Kundanbagh Colony, <br /> Begumpet, Hyderabad, 500016. </p>
                                    </div>
                                    <p>
                                        <img src={icon19} alt="Email Icon" className="img-fluid" />
                                        info@trainingkart.com
                                    </p>
                                    <p className="mb-0">
                                        <img src={icon21} alt="Phone Icon" className="img-fluid" />
                                        +91 8886-25-26-27
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="footer-bottom footer-bolot-color" >
                <div className="container">
                    <hr className="mt-5" />
                    <div id="footer_course_links">
                        {
                            Object.entries(footerCourses).map(([category, courses], index) =>
                                <div key={index}>
                                    <h6 className="footer-list-heading fw-bold"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                    </svg> {category}</h6>
                                    <div className="container">
                                        <div className="row">
                                            <div className=" footer-course-list mt-3 d-flex">
                                                {parse(composeCourseLinksHtml(courses))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="privacy-policy">
                                    <ul>
                                        <li><a href="term-condition.html" className='color-white'>Terms</a></li>
                                        <li><a href="privacy-policy.html" className='color-white'>Privacy</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="copyright-text">
                                    <p className="mb-0 color-white">&copy; 2024 TrainingKart. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </footer >
    );
}

export default Footer;