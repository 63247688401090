import React, { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

export default function Testimonials() {


    const apiUrl = process.env.REACT_APP_API_URL;
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    const hasFetched = useRef(false);
    const [testimonail, setTestimonail] = useState([]);
    useEffect(() => {
        if (hasFetched.current) return; // Prevent second call in dev mode
        fetch(`${apiUrl}Home/getCustomReviews/0/4`).then(
            res => res.json()
        ).then(data => setTestimonail(data?.data))
        hasFetched.current = true;
    }, [apiUrl])

    const getStudentImage =(fileName) => {
        return fileName == null ? null : imageUrl + "uploads/user_image/" + fileName;
    }

    return (
        <>
            <section class="section user-love">
                <div class="container">
                    <div class="section-sub-head feature-head text-center">
                        <span>Testimonials</span>
                        <span>
                            <h2>What our students say</h2>
                        </span>
                    </div>
                </div>
            </section>
            <section class="testimonial-four">
                <div class="">
                    <div class="container">
                        <div class="mentor-testimonial lazy slider" data-aos="fade-up" data-sizes="50vw ">
                            
                            <Splide options={{ rewind: true }} aria-label="React Splide Example">
                            {
                                testimonail.map((review, index) => 
                                    <SplideSlide key={index}>
                                    <div class="d-flex justify-content-center">
                                        <div class="card-body cardtest-2 test-card p-lg-6  mt-5 mx-4 my-4">
                                            <div class="mb-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-quote text-primary" viewBox="0 0 16 16">
                                                    <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"></path>
                                                </svg>
                                            </div>
                                            <p class="lead mb-7 text-dark testimonail-content">
                                                {review.review}
                                            </p>
                                            <div class="d-flex align-items-center">
                                                <figure>
                                                    <img src={getStudentImage(review.filename)} alt="avatar" class="rounded-circle avatar-test avatar-lg" />
                                                </figure>
                                                <div class="ms-3">
                                                    <h4 class="h5 mb-0 testimonail-name">{review.student_name}</h4>
                                                    <small></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                )
                            }
                            </Splide>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}