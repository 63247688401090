
export default function EventCard(props) {

    const imageUrl = process.env.REACT_APP_IMAGE_URL;

    const getEventsBanner = (fileName) => {
        return fileName == null ? null : imageUrl + "uploads/banners/" + fileName;
    }

    return (
        <div className="col-md-4" key={props.events.id}>
            <div className="card events-card">
                <div className="bg-event-card" style={{ backgroundImage: `url(${getEventsBanner(props.events.banner)})` }}>
                    <span className="badge text-bg-light mx-2 my-2">Event</span>
                </div>
                <h3 className="title instructor-text pt-3">{props.events.title}</h3>
                <div className="row">
                    <div className="col-md-6 display-flex">
                        <img src="assets/img/icon/webinar-events.svg" alt="" className="event-time-icon" />
                        <span>{new Date(props.events.timings).toLocaleString()}</span>
                    </div>
                </div>
                <button type="button" data-bs-toggle="modal" data-bs-target="#enrol_event_modal" className="btn btn-primary mt-2">Enroll now</button>
            </div >
        </div>
    )
}