
import HeroBlock from './HeroBlock'
import CompanyHighlights from './CompanyHighlights'
import CategoryCard from './CategoryCard'
import FeaturedCourses from './FeaturedCourses'
import WhatIsNew from './WhatIsNew'
import TrendingCourses from './TrendingCourses'
import UpcomingEvents from './UpcomingEvents'
import FeaturedInstructors from './FeaturedInstructors'
import LeadingCompanies from './LeadingCompanies'
import ShareKnowledge from './ShareKnowledge'
import Testimonials from './Testimonials'
import BecomeInstructor from './BecomeInstructor'
import LatestBlogs from './LatestBlogs'

const Home = () =>{
    return(
        <>
        <HeroBlock />
        <CompanyHighlights />
        <CategoryCard />
        <FeaturedCourses />
        <WhatIsNew />
        <TrendingCourses />
        <UpcomingEvents />
        {/* <FeaturedInstructors /> */}
        <LeadingCompanies />
        <ShareKnowledge />
        <Testimonials />
        <BecomeInstructor />
        <LatestBlogs />
        </>
    )
}

export default Home;