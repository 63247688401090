import IconOne from './assets/img/icon/icon-1.svg';
import IconTwo from './assets/img/icon/icon-2.svg';
import IconThree from './assets/img/icon/icon-3.svg';
import IconFour from './assets/img/icon/icon-4.svg';
import JoinImage from './assets/img/join.png';

export default function WhatIsNew(){
    return (
        <section className="section master-skill">
			<div className="container">
				<div className="row">
					<div className="col-lg-7 col-md-12">
						<div className="section-header aos" data-aos="fade-up">
							<div className="section-sub-head">
								<span>What’s New</span>
								<h2>Master the skills to drive your career</h2>
							</div>
						</div>
						<div className="section-text aos" data-aos="fade-up">
							<p>Get certified, master modern tech skills, and level up your career — whether you’re
								starting out or a seasoned pro. 95% of eLearning learners report our hands-on content
								directly helped their careers.</p>
						</div>
						<div className="career-group aos" data-aos="fade-up">
							<div className="row">
								<div className="col-lg-6 col-md-6 d-flex">
									<div className="certified-group blur-border d-flex">
										<div className="get-certified d-flex align-items-center">
											<div className="blur-box">
												<div className="certified-img ">
													<img src={IconOne} alt="Img" className="img-fluid" />
												</div>
											</div>
											<p>Stay motivated with engaging instructors</p>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 d-flex">
									<div className="certified-group blur-border d-flex">
										<div className="get-certified d-flex align-items-center">
											<div className="blur-box">
												<div className="certified-img ">
													<img src={IconTwo} alt="Img" className="img-fluid" />
												</div>
											</div>
											<p>Keep up with in the latest in cloud</p>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 d-flex">
									<div className="certified-group blur-border d-flex">
										<div className="get-certified d-flex align-items-center">
											<div className="blur-box">
												<div className="certified-img ">
													<img src={IconThree} alt="Img" className="img-fluid" />
												</div>
											</div>
											<p>Get certified with 100+ certification courses</p>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 d-flex">
									<div className="certified-group blur-border d-flex">
										<div className="get-certified d-flex align-items-center">
											<div className="blur-box">
												<div className="certified-img ">
													<img src={IconFour} alt="Img" className="img-fluid" />
												</div>
											</div>
											<p>Build skills your way, from labs to courses</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-5 col-md-12 d-flex align-items-end">
						<div className="career-img aos" data-aos="fade-up">
							<img src={JoinImage} alt="Img" className="img-fluid" />
						</div>
					</div>
				</div>
			</div>
		</section>
		
    );
}