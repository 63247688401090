const PriceTag = ({ price, discounted_price, discount_flag }) => {
  // Convert price to a formatted string with the rupee symbol
  const formatPrice = (amount) => {
    amount = parseInt(amount)
    /*if (typeof amount !== 'number' || isNaN(amount)) {
      return '₹0.00'; // Handle invalid numbers
    }*/
    return `₹${amount}`;
  };

  return (
    <h3>
      {discount_flag ? (
        <>
          
          
          {formatPrice(discounted_price)} <span>{formatPrice(price)}</span> <span className="small-text">+ Taxes</span>
        </>
      ) : (
        <>{formatPrice(price)} <span className="small-text"> + Taxes</span></>
      )}
    </h3>
  );
};


  export default PriceTag;