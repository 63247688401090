import React, { useEffect, useRef, useState } from "react";
import IconSeven from './assets/img/icon/icon-07.svg'
import IconEight from './assets/img/icon/icon-08.svg'
import IconNine from './assets/img/icon/icon-09.svg'
import IconTen from './assets/img/icon/icon-10.svg'
import IconTwelve from './assets/img/icon/icon-12.svg'
import IconThirteen from './assets/img/icon/icon-13.svg'
import IconFourteen from './assets/img/icon/icon-14.svg'
import IconFifteen from './assets/img/icon/icon-15.svg'
import IconSixteen from './assets/img/icon/icon-16.svg'
import IconSeventeen from './assets/img/icon/icon-17.svg'
import IconEighteen from './assets/img/icon/icon-18.svg'
import BlogCard from './BlogCard'

export default function LatestBlogs(){
    const apiUrl = process.env.REACT_APP_API_URL;
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    const hasFetched = useRef(false);
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        if (hasFetched.current) return;
        fetch(`${apiUrl}Blogs/getPaginatedBlogs/1/4`).then(
            res => res.json()
        ).then(data => setBlogs(data?.data))
        hasFetched.current = true;
    }, [apiUrl])

    return (
        
        <section className="section latest-blog">
			<   div className="container">
				<div className="section-header aos" data-aos="fade-up">
					<div className="section-sub-head feature-head text-center mb-0">
						<h2>Latest Blogs</h2>
						<div className="all-btn all-category d-flex align-items-center">
							<a href="blog-grid.php" className="btn btn-primary blogs-btn">More Blogs</a>
						</div>
						<div className="section-text aos" data-aos="fade-up">
							<p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean accumsan bibendum gravida maecenas augue elementum et neque. Suspendisse imperdiet.</p>
						</div>
					</div>
				</div>
				<div className="blogs-slide">
					<div className="container">
						<div className="row justify-content-center" id="blogs_slides">
                                {
                                    blogs && blogs.length > 0 && blogs.map((blog, index) => <BlogCard blog={blog} key={index} />)
                                }
						</div>
					</div>

					
				</div>
				<div className="enroll-group aos" data-aos="fade-up">
					<div className="row ">
						<div className="col-lg-4 col-md-6">
							<div className="total-course d-flex align-items-center">
								<div className="blur-border">
									<div className="enroll-img ">
										<img src={IconSeven} alt="Img" className="img-fluid" />
									</div>
								</div>
								<div className="course-count">
									<h3><span className="counterUp" id="students_enrolled"></span></h3>
									<p>STUDENTS ENROLLED</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="total-course d-flex align-items-center">
								<div className="blur-border">
									<div className="enroll-img ">
										<img src={IconEight} alt="Img" className="img-fluid" />
									</div>
								</div>
								<div className="course-count">
									<h3><span className="counterUp" id="online_courses_two"></span></h3>
									<p>TOTAL COURSES</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="total-course d-flex align-items-center">
								<div className="blur-border">
									<div className="enroll-img ">
										<img src={IconNine} alt="Img" className="img-fluid" />
									</div>
								</div>
								<div className="course-count">
									<h3><span className="counterUp">127</span></h3>
									<p>COUNTRIES</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="lab-course">
					<div className="section-header aos" data-aos="fade-up">
						<div className="section-sub-head feature-head text-center">
							<h2>Unlimited access to 360+ courses <br />and 1,600+ hands-on labs</h2>
						</div>
					</div>
					<div className="icon-group aos" data-aos="fade-up">
						<div className="offset-lg-1 col-lg-12">
							<div className="row">
								<div className="col-lg-1 col-3">
									<div className="total-course d-flex align-items-center">
										<div className="blur-border">
											<div className="enroll-img ">
												<img src={IconNine} alt="Img" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-1 col-3">
									<div className="total-course d-flex align-items-center">
										<div className="blur-border">
											<div className="enroll-img ">
												<img src={IconTen} alt="Img" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-1 col-3">
									<div className="total-course d-flex align-items-center">
										<div className="blur-border">
											<div className="enroll-img ">
												<img src={IconSixteen} alt="Img" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-1 col-3">
									<div className="total-course d-flex align-items-center">
										<div className="blur-border">
											<div className="enroll-img ">
												<img src={IconTwelve} alt="Img" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-1 col-3">
									<div className="total-course d-flex align-items-center">
										<div className="blur-border">
											<div className="enroll-img ">
												<img src={IconThirteen} alt="Img" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-1 col-3">
									<div className="total-course d-flex align-items-center">
										<div className="blur-border">
											<div className="enroll-img ">
												<img src={IconFourteen} alt="Img" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-1 col-3">
									<div className="total-course d-flex align-items-center">
										<div className="blur-border">
											<div className="enroll-img ">
												<img src={IconFifteen} alt="Img" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-1 col-3">
									<div className="total-course d-flex align-items-center">
										<div className="blur-border">
											<div className="enroll-img ">
												<img src={IconSixteen} alt="Img" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-1 col-3">
									<div className="total-course d-flex align-items-center">
										<div className="blur-border">
											<div className="enroll-img ">
												<img src={IconSeventeen} alt="Img" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-1 col-3">
									<div className="total-course d-flex align-items-center">
										<div className="blur-border">
											<div className="enroll-img ">
												<img src={IconEighteen} alt="Img" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    );
}