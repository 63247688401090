import './CourseCard.css'
import PriceTag from './PriceTag';
export default function CourseCard(props) {
    const imageUrl = process.env.REACT_APP_IMAGE_URL;

    const getCourseThumbnailUrl = (fileName) => {
        return fileName == null ? null : imageUrl + fileName;
    }

    const getInstructorImage = (image) => {
        return imageUrl + "uploads/user_image/" + image + ".jpg";
    }

    return (
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex" key={props.course.id} data-aos="fade-down">
            <div className="featured-details-two">
                <div className="product-item-two">
                    <div className="product-img-two">
                        <a href="course-details.html">
                            <img className="img-fluid" alt="Img" src={getCourseThumbnailUrl(props.course.thumbnail)} />
                        </a>
                    </div>
                    <div className="course-details-content">
                        <div className="image-info">
                            <img className="img-fluid" src={getInstructorImage(props.course.instructor.image)} alt={props.course.instructor.first_name} />
                        </div>
                        <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <span className="ms-3">203 reviews</span>
                        </div>

                        <div className="name-text featured-info-two">
                            <h3 className="title instructor-text"><a href="course-details.html">{props.course.title}</a></h3>
                            <p className="me-3">By<span className="text-danger ms-2">{props.course.instructor.first_name + ' ' + props.course.instructor.last_name}</span>
                            </p>
                        </div>

                        <div className="featured-info-time d-flex align-items-center">
                            <div className="hours-time-two d-flex align-items-center">
                                <span><i className="fa-regular fa-clock me-2"></i></span>
                                <p>{props.course.course_duration_in_hours}{props.course.course_duration_in_hours === 1 ? ' Hour' : ' Hours'}</p>
                            </div>
                            <div className="course-view d-inline-flex align-items-center">
                                <div className="course-price">
                                    <PriceTag
                                        price={props.course.price}
                                        discounted_price={props.course.discounted_price}
                                        discount_flag={props.course.discount_flag}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}