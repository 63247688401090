import logo from './assets/img/logo.svg';
import hamberg from './assets/img/icon/hamberg-icon.svg';
import cart from './assets/img/icon/cart.svg';
import course4 from './assets/img/course/course-04.jpg';
import course14 from './assets/img/course/course-14.jpg';
import wish from './assets/img/icon/wish.svg';
import user16 from './assets/img/user/user16.jpg';

const menu = [
	{"title":"Refer A Friend", "has_sub_manu":false, "destination":"/refer_a_friend", "auth":false},
	{"title":"Become A Instructor", "has_sub_manu":false, "destination":"/become_a_instructor", "auth":false},
	{"title":"Jobs", "has_sub_manu":false, "destination":"/jobs", "auth":false},
	{"title":"Blogs", "has_sub_manu":false, "destination":"/blogs", "auth":false},
];
const Nav = () => {
    return (
        <header className="header header-page">
	<div className="header-fixed">
		<nav className="navbar navbar-expand-lg header-nav scroll-sticky">
			<div className="container">
				<div className="navbar-header">
					<a id="mobile_btn" onClick={(e) => e.preventDefault()}>
						<span className="bar-icon">
							<span></span>
							<span></span>
							<span></span>
						</span>
					</a>
					<a href="/home" className="navbar-brand logo">
						<img src={logo} className="img-fluid" alt="Logo" />
					</a>
				</div>
				<div className="main-menu-wrapper">
					<div className="menu-header">
						<a href="/home" className="menu-logo">
							<img src={logo} className="img-fluid" alt="Logo" />
						</a>
						<a id="menu_close" className="menu-close" onClick={(e) => e.preventDefault()}>
							<i className="fas fa-times"></i>
						</a>
					</div>
					<ul className="main-nav">
						<li className="has-submenu active">
							<a className="course-list-dropdown" onClick={(e) => e.preventDefault()}>Courses
								<img src={hamberg} />
								<ul className="submenu first-submenu">

								</ul>
							</a>
							<ul className="submenu" id="header_categories">
								
							</ul>
						</li>
						<li className="has-submenu ">
							<a className="" onClick={(e) => e.preventDefault()}>Refer A Friend</a>
						</li>
						<li className="has-submenu ">
							<a className="" onClick={(e) => e.preventDefault()}>Become a Instructor</a>
						</li>
						<li className="has-submenu ">
							<a className="" onClick={(e) => e.preventDefault()}>Jobs</a>
						</li>
						<li className="has-submenu ">
							<a className="" onClick={(e) => e.preventDefault()}>Blogs</a>
						</li>

						<li className="login-link">
							<a onClick={(e) => e.preventDefault()}>Login / Signup</a>
						</li>
					</ul>
				</div>
				<ul className="nav header-navbar-rht visibility-none">
					<li className="nav-item cart-nav">
						<a onClick={(e) => e.preventDefault()} className="dropdown-toggle" data-bs-toggle="dropdown">
							<img src={cart} alt="img" />
						</a>
						<div className="wishes-list dropdown-menu dropdown-menu-right">
							<div className="wish-header">
								<a onClick={(e) => e.preventDefault()}>View Cart</a>
								<a onClick={(e) => e.preventDefault()} className="float-end">Checkout</a>
							</div>
							<div className="wish-content">
								<ul>
									<li>
										<div className="media">
											<div className="d-flex media-wide">
												<div className="avatar">
													<a onClick={(e) => e.preventDefault()}>
														<img alt="Img" src={course4} />
													</a>
												</div>
												<div className="media-body">
													<h6><a onClick={(e) => e.preventDefault()}>Learn Angular...</a></h6>
													<p>By Dave Franco</p>
													<h5>$200 <span>$99.00</span></h5>
												</div>
											</div>
											<div className="remove-btn">
												<a onClick={(e) => e.preventDefault()} className="btn">Remove</a>
											</div>
										</div>
									</li>
									<li>
										<div className="media">
											<div className="d-flex media-wide">
												<div className="avatar">
													<a onClick={(e) => e.preventDefault()}>
														<img alt="Img" src={course14} />
													</a>
												</div>
												<div className="media-body">
													<h6><a onClick={(e) => e.preventDefault()}>Build Responsive Real...</a></h6>
													<p>Jenis R.</p>
													<h5>$200 <span>$99.00</span></h5>
												</div>
											</div>
											<div className="remove-btn">
												<a onClick={(e) => e.preventDefault()} className="btn">Remove</a>
											</div>
										</div>
									</li>
									
								</ul>
								<div className="total-item">
									<h6>Subtotal : $ 600</h6>
									<h5>Total : $ 600</h5>
								</div>
							</div>
						</div>
					</li>
					<li className="nav-item wish-nav">
						<a onClick={(e) => e.preventDefault()} className="dropdown-toggle" data-bs-toggle="dropdown" id="wishListItemsBtn">
							<img src={wish} alt="img" />
						</a>
						<div className="wishes-list dropdown-menu dropdown-menu-right">
							<div className="wish-header">
								<a onClick={(e) => e.preventDefault()}>View Wishlist</a>
								<a onClick={(e) => e.preventDefault()} className="float-end">View Cart</a>
							</div>
							<div className="wish-content">
								<ul id="wish-content-items">

								</ul>
							</div>
						</div>
					</li>
				</ul>
				<ul className="nav header-navbar-rht visibility-none" id="profile_links" >

					<li className="nav-item user-nav">
						<a onClick={(e) => e.preventDefault()} className="dropdown-toggle" data-bs-toggle="dropdown">
							<span className="user-img">
								<img src={user16} alt="Img" />
								<span className="status online"></span>
							</span>
						</a>
						<div className="users dropdown-menu dropdown-menu-right" data-popper-placement="bottom-end">
							<div className="user-header">
								<div className="avatar avatar-sm">
									<img src={user16} alt="User Image" className="avatar-img rounded-circle" />
								</div>
								<div className="user-text">
									<h6 id="student_name"></h6>
									<p className="text-muted mb-0">Student</p>
								</div>
							</div>
							<a className="dropdown-item" onClick={(e) => e.preventDefault()}><i className="feather-home me-1"></i> Dashboard</a>
							<a className="dropdown-item" onClick={(e) => e.preventDefault()}><i className="feather-user me-1"></i> Profile</a>
							<a className="dropdown-item" onClick={(e) => e.preventDefault()}><i className="feather-star me-1"></i> My Courses</a>
							<a className="dropdown-item" onClick={(e) => e.preventDefault()} id="logout_link"><i className="feather-log-out me-1"></i> Logout</a>
						</div>

					</li>
				</ul>
				<ul className="nav header-navbar-rht" id="auth_links">
					<li className="nav-item">
						<a className="nav-link header-login" onClick={(e) => e.preventDefault()}>Enroll Now</a>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</header>
    );
}

export default Nav;