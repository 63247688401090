import './App.css';
import { Routes, Route } from 'react-router-dom';
import Nav from './Nav.js'
import Footer from './Footer.js'
import Home from './Home.js'

function App() {
  return (
      <>
      
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
      </Routes>
      <Footer />
      </>
  );
}

export default App;
