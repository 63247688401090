import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const LeadingCompanies = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const companies = [
        "Accenture.png", "CES.png", "Cyient.png", "Deloitte.png", "Doyensys.png",
        "Evosys.png", "FIS.png", "Genpact.png", "Gevin.png", "Hackett_Group.webp",
        "HCL.png", "Infosys.png", "Mphasis.png", "NTT_Data.png", "Oracle_Database.png",
        "SLK.png", "Splash_BI.png", "Tech_Mahindra.png"
    ];

    return (
        
        <section className="section lead-companies">
            <div className="container">
                <div className="section-header aos" data-aos="fade-up">
                    <div className="section-sub-head feature-head text-center">
                        <span>Trusted By</span>
                        <h2>500+ Leading Universities And Companies</h2>
                    </div>
                </div>
                <div className="lead-group">
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                        keyBoardControl={true}
                        transitionDuration={15000}
                        containerClassName="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                        dotListClassName="custom-dot-list-style"
                        itemClassName="carousel-item-padding-40-px"
                    >
                        {companies.map((company, index) => (
                            <div key={index}>
                                <img
                                    src={require(`./assets/img/company_logos/${company}`)} // Adjust the path as necessary
                                    alt={company.split('.')[0]}
                                    style={{ width: '150px', height: 'auto' }}
                                />
                            </div>
                        ))}

                    </Carousel>
                </div>
            </div>
		</section >
    );
};

export default LeadingCompanies;



