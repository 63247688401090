import { useState, useEffect, useRef } from 'react';
import CourseCard from './CourseCard'
export default function FeaturedCourses() {

    const apiUrl = process.env.REACT_APP_API_URL;

    const hasFetched = useRef(false);
    const [featuredCourses, setFeaturedCourses] = useState([]);
    useEffect(() => {
        if (hasFetched.current) return; // Prevent second call in dev mode
        fetch(`${apiUrl}Home/getCoursesList/top`).then(
            res => res.json()
        ).then(data => setFeaturedCourses(data?.data))
        hasFetched.current = true;
    }, [apiUrl])




    return (

        <section className="featured-courses-sec">
            <div className="patter-one-main">
                <img className="patter-one" src="assets/img/bg/patter-one.png" alt="Img" />
            </div>
            <div className="container">
                <div className="header-two-title text-center" data-aos="fade-up">
                    <p className="tagline">What’s New</p>
                    <h2>Featured Courses</h2>
                    <div className="header-two-text m-auto text-center d-block">
                        <p className="mb-0">Discover our handpicked selection of Featured Courses, meticulously crafted to deliver high-quality learning experiences that inspire and empower. From foundational concepts to advanced techniques, each course is designed by industry experts to ensure relevance, practicality, and effectiveness.</p>
                    </div>
                </div>
                <div className="featured-courses-two">
                    <div className="row justify-content-center">


                        {
                            featuredCourses.map((course, index) => <CourseCard key={index} course={course} />)
                        }



                    </div>
                </div>

                <div className="col-lg-12" data-aos="fade-up">
                    <div className="more-details text-center">
                        <a href="course-details.html" className="discover-btn">View all Courses <i
                            className="fas fa-arrow-right ms-2"></i></a>
                    </div>
                </div>

            </div>
        </section>


    );
}